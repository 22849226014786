import { StaticImage } from 'gatsby-plugin-image';
import * as React from 'react';
import PrimaryButton from '../../../components/button/primary-button';
import * as heroStyles from './hero.module.scss';
import { navigate } from 'gatsby';
import i18next from "i18next";

const Hero = () => {
    return (
        <section className={heroStyles.hero}>
            <StaticImage alt="logo" src='../../../images/Hero-section.png' />
            <div className={heroStyles.hero__slogan}>
                <h1 className={heroStyles.hero__quote}>{i18next.t('hero.title')}</h1>
                <PrimaryButton onClick={() => navigate('/contact-us')} className="font-s2r px-5r">{i18next.t('hero.action.lets_talk')}</PrimaryButton>
            </div>
        </section>
    )
}
export default Hero
